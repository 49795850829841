import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-stickynode";
import FlareLogo from "../assets/flare/flare_logo";

const CustomNavbar = (props) => {
  const [navState, setNavState] = useState(0);
  var { mClass, nClass, cClass, slogo, hbtnClass, isRoot } = props;
  return (
    <Sticky
      top={0}
      innerZ={9999}
      activeClass="navbar_fixed"
      onStateChange={({ status }) => setNavState(status)}
    >
      <header className="header_area">
        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
          <div className={`container ${cClass}`}>
            <Link className={`navbar-brand ${slogo}`} to="/">
              {navState === 2 ? (
                <FlareLogo width={35} />
              ) : (
                <>
                  <img
                    src={require("../img/home7/flare.png")}
                    style={{ width: 50 }}
                    alt=""
                  />
                  <img
                    src={require("../img/home7/flare.png")}
                    style={{ width: 50 }}
                    alt="logo"
                  />
                </>
              )}
            </Link>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="menu_toggle">
                <span className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span className="hamburger-cross">
                  <span></span>
                  <span></span>
                </span>
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                <li className="dropdown submenu nav-item">
                  <a
                    href="/#about-us"
                    className="nav-link"
                  >
                    About
                  </a>
                </li>
                <li className="dropdown submenu nav-item">
                  <a
                    href="/#features"
                    title="Features"
                    className="nav-link"
                  >
                    Features
                  </a>
                </li>
                <li className="dropdown submenu nav-item">
                  <a
                    title="Download"
                    className="nav-link"
                    href="/#get-app"
                  >
                    Download
                  </a>
                </li>
                <li className="nav-item">
                  <NavLink title="Contact" className="nav-link" to="/Contact">
                    Contact
                  </NavLink>
                </li>
              </ul>
              <a className={`btn_get btn_hover ${hbtnClass}`} href="/#get-app">
                Get Started
              </a>
            </div>
          </div>
        </nav>
      </header>
    </Sticky>
  );
};

export default CustomNavbar;
