import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1074 633"
      width={200}
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__linear-gradient"
          x1={537.37}
          y1={112.5}
          x2={537.37}
          y2={331.13}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#927dfe" />
          <stop offset={1} stopColor="#9627ff" />
        </linearGradient>
        <style>{".prefix__cls-1{fill:#9627ff}"}</style>
      </defs>
      <circle className="prefix__cls-1" cx={537.37} cy={285.37} r={22.03} />
      <path
        className="prefix__cls-1"
        d="M428.64 375.56c.15-11.55 10-20.75 21.68-20.75A21.74 21.74 0 01472 375.56l.1 7.26a21.79 21.79 0 1043.58 0l-.29-30.43a21.7 21.7 0 0143.37 0l-.19 30.43a21.79 21.79 0 1043.58 0l.1-7.26c0-13.93 10-20.75 21.68-20.75a21.74 21.74 0 0121.68 20.75l.1 9.52c0 79.93-108.25 120.6-108.25 120.6S428.9 465 428.9 387.13c0 0-.3-8.67-.26-11.57z"
      />
      <rect
        className="prefix__cls-1"
        x={123.84}
        y={217.58}
        width={40.67}
        height={183.04}
        rx={20.34}
        ry={20.34}
      />
      <rect
        className="prefix__cls-1"
        x={276.37}
        y={217.58}
        width={40.67}
        height={183.04}
        rx={20.34}
        ry={20.34}
      />
      <rect
        className="prefix__cls-1"
        x={165.37}
        y={176.05}
        width={45.76}
        height={128.8}
        rx={22.16}
        ry={22.16}
        transform="rotate(90 188.245 240.455)"
      />
      <rect
        className="prefix__cls-1"
        x={319.31}
        y={315.31}
        width={42.93}
        height={128.8}
        rx={21.47}
        ry={21.47}
        transform="rotate(90 340.775 379.705)"
      />
      <rect
        className="prefix__cls-1"
        x={155.2}
        y={255.71}
        width={44.06}
        height={106.77}
        rx={21.69}
        ry={21.69}
        transform="rotate(90 177.225 309.095)"
      />
      <rect
        className="prefix__cls-1"
        x={842.43}
        y={217.58}
        width={40.67}
        height={183.04}
        rx={20.34}
        ry={20.34}
      />
      <rect
        className="prefix__cls-1"
        x={885.36}
        y={174.64}
        width={42.93}
        height={128.8}
        rx={21.47}
        ry={21.47}
        transform="rotate(90 906.83 239.04)"
      />
      <rect
        className="prefix__cls-1"
        x={885.36}
        y={314.74}
        width={42.93}
        height={128.8}
        rx={21.47}
        ry={21.47}
        transform="rotate(90 906.835 379.145)"
      />
      <rect
        className="prefix__cls-1"
        x={874.46}
        y={256.73}
        width={42.71}
        height={106.77}
        rx={21.35}
        ry={21.35}
        transform="rotate(90 895.815 310.115)"
      />
      <rect
        className="prefix__cls-1"
        x={721.25}
        y={280.33}
        width={42.35}
        height={142.94}
        rx={20.65}
        ry={20.65}
        transform="rotate(-54.84 742.388 351.803)"
      />
      <rect
        className="prefix__cls-1"
        x={679.73}
        y={217.58}
        width={42.35}
        height={183.51}
        rx={21.17}
        ry={21.17}
      />
      <path
        className="prefix__cls-1"
        d="M727.5 344.62h-27.3c-11.26 0-20.47-9.53-20.47-21.17 0-11.65 9.21-21.17 20.47-21.17h51.18c11.31 0 20.47-9.48 20.47-21.17s-9.17-21.17-20.47-21.17H700.2c-11.26 0-20.47-9.53-20.47-21.17 0-11.65 9.21-21.17 20.47-21.17H748c33.92 0 61.41 28.44 61.41 63.52s-27.5 63.52-61.41 63.52z"
      />
      <path
        d="M471 308.39a21 21 0 11-42.08 0V159a21.1 21.1 0 0121-21c11.57 0 21.33 8.47 21.33 22 0 10.12 1.69 27.12 22.88 27.12A22.88 22.88 0 00517 164.19v-30.75a20.94 20.94 0 1141.89 0s.42 30.81.48 35.83c.15 12.17 9.67 22 21.61 22s21.61-7.63 21.61-22 9.72-21.18 21.61-21.18a21.46 21.46 0 0121.61 21.18v140.67a21.61 21.61 0 01-43.22 0v-28c0-35.17-29.49-63.68-65.87-63.68s-65.87 28.51-65.87 63.68z"
        fill="url(#prefix__linear-gradient)"
      />
    </svg>
  )
}

export default SvgComponent
