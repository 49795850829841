import React,{Component} from 'react';
import AddIcon from '@mui/icons-material/Add';
import MapIcon from '@mui/icons-material/Map';
import FlareLogo from '../../assets/flare/flare_logo';

class AppWork extends Component{
    render(){
        const appFeatures = [
            {
                id: 1,
                title: 'Create',
                icon: <AddIcon style={{color: '#9627FF', fontSize: 35, fontWeight: 'bold'}} />,
                p: 'Create spontaneous gatherings and set the timer for them to last. Send notifications to those you want, but all your friends see it anyway.'
            },
            {
                id: 2,
                title: 'Discover',
                icon: <MapIcon style={{color: '#9627FF', fontSize: 35, fontWeight: 'bold'}} />,
                p: 'Never let your FOMO win. Find and join your friends on their meetups. Never miss out again.'
            },
            {
                id: 3,
                title: 'Flare',
                icon: <FlareLogo />,
                p: "It's spontaneous, it's simple, it's hassle free. Flare your next event and make everyone feel invited. No more annoying groups."
            }
        ]
        return(
            <section className="app_service_area" id="about-us">
                <div className="container">
                    {/* <Sectitle sClass="sec_title text-center mb_70" Title="Features" tClass="t_color3" /> */}
                    <div className="row app_service_info">
                        {
                            appFeatures.map(items => {
                                return(
                                    <div className="col-lg-4"key={items.id}>
                                        <div className="app_service_item wow fadeInUp" data-wow-delay="0.2s">
                                            {/* <i className={`app_icon ${items.icon}`}></i> */}
                                            {items.icon}
                                            <h5 className="f_p f_size_18 f_600 t_color3 mt_40 mb-30">{items.title}</h5>
                                            <p className="f_400 f_size_15 mb-30">{items.p}</p>
                                        </div>
                                    </div> 
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}
export default AppWork;