import React from "react";
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
const NotFound = () => (
  <div className="body_wrapper">
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img src={require("../img/new/error.png")} alt="" />
          <h2>Error. We can’t find the page you’re looking for.</h2>
          <p>
            Sorry for the inconvenience. Go to our homepage to check out what's happening...{" "}
          </p>
          <form action="#" className="search">
            <input type="text" className="form-control" placeholder="search" />
          </form>
          <a href="/" className="about_btn btn_hover">
            Back to Home Page <i className="arrow_right"></i>
          </a>
        </div>
      </div>
    </section>
    <Footer FooterData={FooterData}/>

  </div>
);
export default NotFound;
