import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 259.13 443.12"
      fill="#9627ff"
      width={props.width || 30}
      {...props}
    >
      <rect
        className="prefix__cls-1"
        x={107.82}
        width={42.31}
        height={118.11}
        rx={21}
        ry={21}
      />
      <rect
        className="prefix__cls-1"
        y={94.41}
        width={49.24}
        height={54.13}
        rx={22.65}
        ry={22.65}
      />
      <path
        d="M165.77 378.7v65a24.93 24.93 0 0049.87 0l.57-78.9a26 26 0 0125.73-26.23c14.21 0 25.72 11.75 25.72 26.23v37.95c0 13.88 11.58 25.22 25.72 25.22s25.73-11.34 25.73-25.22l.89-254.43a25.12 25.12 0 00-25.05-25c-20.35 0-25.58 16.27-25.39 25.22v127.44c-.6 14.27-12.73 25.58-27.22 25.58A27.24 27.24 0 01215 274.28v-78a24.93 24.93 0 00-49.87 0l-.57 87.5a26 26 0 01-25.73 26.23c-14.21 0-25.72-11.75-25.72-26.23v-53.42c0-13.88-11.58-25.22-25.72-25.22s-25.73 11.34-25.73 25.22l-.86 148.51a25.13 25.13 0 0025.05 25.05c20.35 0 25.58-16.27 25.39-25.22-.3-15 12.2-27.24 27.24-27.24a27.24 27.24 0 0127.29 27.24z"
        transform="translate(-60.84 -25.57)"
      />
    </svg>
  )
}

export default SvgComponent