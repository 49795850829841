import React from 'react';
import Reveal from 'react-reveal';
const AppFeatures = ({ title, description, imagePath, id }) => {
    return(
        <section id={id} className="app_featured_area_two">
            <div className="triangle_shape"></div>
            <div className="container">
                <div className="row app_feature_info">
                    <div className="col-lg-5">
                        <img src={require(`../../assets/flare/${imagePath}.png`)} style={{width: 250}} />
                    </div>
                    <div className="col-lg-5 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="app_featured_content">
                                <h2 className="f_p f_size_30 f_700 t_color3 l_height45 mb-30">{title}</h2>
                                <p className="f_400">{description}</p>
                            </div>
                        </Reveal>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AppFeatures;
