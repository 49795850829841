import React, { Component } from "react";
import { Link } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import FlareLogoWritten from "../../assets/flare/flare_logo_written";
class Footer extends Component {
  render() {
    let FooterData = this.props.FooterData;
    return (
      <footer className="new_footer_area bg_color">
        <div className="new_footer_top">
          <div className="container">
            <div className="row">
              {FooterData.AboutWidget.map((widget) => {
                return (
                  <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                    <div className="col-lg-3 col-md-6">
                      <div
                        className="f_widget about-widget pl_70 wow fadeInLeft"
                        data-wow-delay="0.4s"
                      >
                        <h3 className="f-title f_600 t_color f_size_18">
                          {widget.title}
                        </h3>
                        <ul className="list-unstyled f_list">
                          {widget.menuItems.map((item) => {
                            if (
                              item.id === "privacy-policy" ||
                              item.id === "terms-and-conditions"
                            ) {
                              return (
                                <li>
                                  <a
                                    style={{ color: "#6a7695" }}
                                    href={`https://www.iubenda.com/${item.id}/47026099`}
                                    class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
                                    title={item.text}
                                  >
                                    {item.text}
                                  </a>
                                </li>
                              );
                            }
                            return (
                              <li key={item.id}>
                                <Link to={item.url}>{item.text}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Reveal>
                );
              })}
              <FlareLogoWritten />
              {/* {
                                FooterData.SocialLinks.map(widget=>{
                                    return(
                                        <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                                <h3 className="f-title f_600 t_color f_size_18">{widget.title}</h3>
                                                <div className="f_social_icon">
                                                    {
                                                        widget.menuItems.map(item =>{
                                                            return(
                                                                <Link to="/" key={item.id}><i className={item.icon}></i></Link>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        </Reveal>
                                    )
                                })
                            } */}
            </div>
          </div>
          <div className="footer_bg">
            <div className="footer_bg_one"></div>
            <div className="footer_bg_two"></div>
          </div>
        </div>
        <div className="footer_bottom"></div>
      </footer>
    );
  }
}
export default Footer;
