import React, {Component} from 'react';
import Reveal from "react-reveal"

class AppFeaturesTwo extends Component{
    render(){
        return(
            <section className="app_featured_area" id="features">
                <div className="container">
                    <div className="row flex-row-reverse app_feature_info">
                        <div className="col-lg-5">
                            <img src={require('../../assets/flare/app_4.png')} style={{width: 250}} />
                        </div>
                        <div className="col-lg-5" style={{display: 'flex', alignItems: 'center'}}>
                          <Reveal bottom cascade>
                              <div className="app_featured_content">
                                <h2 className="f_p f_size_30 f_700 t_color3 l_height45 pr_70 mb-30">Everywhere, always</h2>
                                <p className="f_400">You can always find what your friends are doing, or share your own plans with your group. It's real time.</p>
                              </div>
                          </Reveal>
                            
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default AppFeaturesTwo;