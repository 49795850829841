import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import AppBanner from '../components/Banner/AppBanner'
import AppFeatures from '../components/Features/AppFeatures';
import AppFeaturesTwo from '../components/Features/AppFeaturesTwo';
import AppGetstarted from '../components/AppGetstarted';
import AppWork from '../components/Service/AppWork'
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';



export const Home = () => (
    <div className="body_wrapper">
        <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
        <AppBanner/>
        <AppWork />
        <AppFeatures
            id="features"
            title="Never miss out on any plan" 
            description="No need to go through tedious back and forths, nor spend hours to find what to do. Just Flare up and find your next adventure." 
            imagePath="app_2" />
        <AppFeaturesTwo />
        <AppFeatures 
            title="Fast and easy" 
            description="Configure your Flare in 2 simple steps: Set the time and place. Start enjoying." 
            imagePath="app_3" />
        <AppGetstarted />
        <Footer FooterData={FooterData}/>
    </div>
)